
.leftbloodyalign {
  clear: both;
}

/* reset */
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, q, small, strike, strong, sub, sup, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}

/* fonts */
@font-face {
  font-family: 'OpenSansRegular';
  src: url('../fonts/opensans-regular-webfont.woff') format('woff'), url('../fonts/opensans-regular-webfont.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}


/*@font-face { font-family: 'OpenSansRegular'; src: url('../fonts/opensans-regular-webfont.eot'); src: url('../fonts/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'), url('../fonts/opensans-regular-webfont.woff') format('woff'), url('../fonts/opensans-regular-webfont.ttf') format('truetype'), url('../fonts/opensans-regular-webfont.svg#OpenSansRegular') format('svg'); font-weight: normal; font-style: normal; }*/

.price {
  color: #6c80cd;
  font-weight: bold;
}

/* basic */
body {
  font: 13px/21px "OpenSansRegular", "HelveticaNeue", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #222;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit;
}

h1 {
  font-size: 28px;
  line-height: 34px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

h2 {
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 4px;
  text-transform: uppercase;
}

h3 {
  font-size: 17px;
  line-height: 24px;
}

h3.bold, h6.bold {
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: bold;
}

h4 {
  font-size: 16px;
  line-height: 21px;
}

h5 {
  font-size: 15px;
  line-height: 21px;
}

h5.bold {
  text-transform: uppercase;
  font-weight: bold;
}

h6 {
  font-size: 14px;
  line-height: 21px;
}

p {
  margin: 0 0 20px;
}

p.small, ul.small {
  font-size: 13px;
}

p.smaller, ul.smaller {
  font-size: 12px;
}

p.gray {
  color: #777
}

p img {
  margin: 0;
}

em {
  font-style: italic
}

strong {
  font-weight: bold
}

small {
  font-size: 80%
}

blockquote, blockquote p {
  line-height: 24px;
  color: #777;
  font-style: italic;
}

blockquote {
  font-size: 12px;
  border-left: 1px solid #ddd;
  margin: 0 0 20px;
  padding: 10px 20px 10px 19px;
}

blockquote cite {
  display: block;
  text-indent: 42px;
  color: #555;
  position: relative;
}

blockquote cite:before {
  content: "\201c";
  font-weight: bold;
  font-style: normal;
  font: 94px/74px Arial;
  position: absolute;
  top: -2px;
  left: -36px;
}

blockquote cite a, blockquote cite a:visited, blockquote cite a:visited {
  color: #555;
}

hr {
  border: solid #ddd;
  clear: both;
  height: 0;
  border-width: 1px 0 0;
  margin: 10px 0 30px;
}

a, a:visited {
  color: #333;
  text-decoration: underline;
  outline: 0;
}

a:hover, a:focus {
  color: #000;
}

p a, p a:visited {
  line-height: inherit;
}

ul, ol {
  margin-bottom: 20px;
}

ul {
  list-style: none outside;
}

ol {
  list-style: decimal;
}

ol, ul.square, ul.circle, ul.disc {
  margin-left: 20px;
}

ul.square {
  list-style: square outside;
}

ul.circle {
  list-style: circle outside;
}

ul.disc {
  list-style: disc outside;
}

ol.roman {
  list-style: upper-roman;
}

ol.alpha {
  list-style: lower-alpha;
}

ul ul, ul ol, ol ol, ol ul {
  font-size: 90%;
  margin: 10px 0 6px 20px;
}

ul ul li, ul ol li, ol ol li, ol ul li {
  margin-bottom: 6px;
}

li {
  line-height: 18px;
  margin-bottom: 12px;
}

ul.smaller li {
  line-height: 18px;
  margin-bottom: 4px;
}

ul.large li, li p {
  line-height: 21px;
}

img.scale {
  max-width: 100%;
  height: auto;
}

p img.left {
  float: left;
  margin: 10px 20px 10px 0;
}

p img.right {
  float: right;
  margin: 10px 0 10px 20px;
}

form, fieldset {
  margin-bottom: 20px;
}

.button, button, input[type=submit], input[type=reset], input[type=button] {
  background: #6c80cd url(../img/pattern.png);
  color: #333;
  border: 0;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: 0;
  line-height: normal;
  font-family: OpenSansRegular, HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  -ms-transition: all .2s linear;
  transition: all .2s linear;
  padding: 8px 12px;
}

.button.small, button.small, input[type=submit].small, input[type=reset].small, input[type=button].small {
  font-size: 11px;
  margin-bottom: 0;
  padding: 0 4px;
}

.button.big, button.big, input[type=submit].big, input[type=reset].big, input[type=button].big {
  font-size: 21px;
  padding: 15px 30px;
}

.button.gray, button.gray, input[type=submit].gray, input[type=reset].gray, input[type=button].gray {
  background-color: #ddd
}

.button.dark, button.dark, input[type=submit].dark, input[type=reset].dark, input[type=button].dark {
  background-color: #222;
  color: #eee;
}

.button:hover, button:hover, input[type=submit]:hover, input[type=reset]:hover, input[type=button]:hover {
  color: #fff;
  background: #222;
}

.button:active, button:active, input[type=submit]:active, input[type=reset]:active, input[type=button]:active {
  color: #eee;
  background: #333;
}

.button.full-width, button.full-width, input[type=submit].full-width, input[type=reset].full-width, input[type=button].full-width {
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  text-align: center;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input[type=text], input[type=password], input[type=email], textarea, select {
  border: 1px solid #ccc;
  outline: none;
  font: 13px OpenSansRegular, HelveticaNeue, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #777;
  width: 230px;
  max-width: 100%;
  display: block;
  background: #f9f9f9;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  margin: 0 0 20px;
  padding: 6px 4px;
}

input.full-width, input[type=text].full-width, input[type=password].full-width, input[type=email].full-width, textarea.full-width, select.full-width {
  width: 100%;
}

.ie7 input.full-width, .ie7 input[type=text].full-width, .ie7 input[type=password].full-width, .ie7 input[type=email].full-width, .ie7 textarea.full-width, .ie7 select.full-width {
  width: 85%;
}

textarea {
  width: 450px;
  min-height: 60px;
  overflow: auto;
}

input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, textarea:focus, select:focus {
  border: 1px solid #aaa;
  color: #333;
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, .2);
  box-shadow: 0 0 3px rgba(0, 0, 0, .2);
}

input:focus:invalid, textarea:focus:invalid {
  border-color: #e55;
}

::-webkit-input-placeholder, :-moz-placeholder {
  color: #ccc;
}

input.inline {
  display: inline-block;
}

label, legend {
  display: block;
  font-weight: bold;
  font-size: 13px;
}

label.checkbox {
  margin-bottom: 12px;
}

select {
  width: 230px;
}

.oldie select {
  width: 240px;
}

input[type=checkbox], input[type=radio] {
  display: inline;
  position: relative;
  top: 1px;
}

label span, legend span {
  font-weight: normal;
  font-size: 13px;
  color: #444;
}

table caption {
  text-align: left;
}

table th, table td {
  padding-left: 6px;
  padding-right: 6px;
  line-height: 21px;
  border: 1px solid #ddd;
  background-color: #fff;
  text-align: left;
  vertical-align: middle;
}

table th, table tfoot td {
  font-weight: bold;
  background: #eee url(../img/pattern.png);
}

table .alternate td {
  background-color: #fafafa;
}

table.large th, table.large td {
  padding: 12px 8px;
}

.remove-bottom {
  margin-bottom: 0 !important;
}

.remove-margin {
  margin: 0 !important;
}

.half-bottom {
  margin-bottom: 10px !important;
}

.add-bottom {
  margin-bottom: 20px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.hide-on-mobile {
  display: none;
}

html, body {
  width: 100%;
  height: 100%;
}

.container div.sep {
  clear: both;
  float: none;
  display: block;
  width: auto;
  margin-bottom: 30px;
}

div.sep-small {
  height: 1px;
  background-color: #ddd;
}

div.sep-big {
  height: 4px;
  background: #6c80cd url(../img/pattern.png);
}

.box {
  background: #f6f6f6;
  margin-bottom: 20px;
  padding: 10px;
}

.box p:last-child {
  margin-bottom: 0;
}

/* sticky footer trick */
.wrapper-frame {
  display: table;
  width: 100%;
}

.ie7 .wrapper-frame {
  height: 100%;
}

.wrapper-row {
  display: table-row;
  height: 1px;
}

html > body .wrapper-expand {
  height: auto;
}

/* 960 grid system */
.container {
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding: 0;
}

.ie7 .container {
  position: static;
}

.container .col {
  width: 100%;
}

body .container .col.alpha {
  margin-left: 0;
}

body .container .col.omega {
  margin-right: 0;
}

.container:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix:before, .clearfix:after, .row:before, .row:after {
  content: '\0020';
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

.row:after, .clearfix:after {
  clear: both;
}

.row, .clearfix {
  zoom: 1
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

/* flexslider */
.flexslider .slides, .flex-control-nav, .flex-direction-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.flexslider {
  position: relative;
  zoom: 1;
  margin: 0 0 30px;
}

html[xmlns] .flexslider .slides {
  display: block;
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slides li {
  margin-bottom: 0;
}

.flexslider .slides img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.flexslider .slides:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

* html .flexslider .slides {
  height: 1%;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
}

/*.flexslider .new-ribbon { display: block; width: 45px; height: 45px; position: absolute; top: -2px; right: -2px; background: url(../img/sprite-gray-yellow.png) no-repeat -255px 0; z-index: 4; }*/
.loading .new-ribbon {
  display: none;
}

.loading .flex-viewport {
  max-height: 0;
  overflow: hidden;
}

.flexslider .carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  height: 0;
}

.loading .flex-direction-nav {
  display: none;
}

.flex-direction-nav a {
  width: 20px;
  height: 30px;
  display: block;
  background: url(../img/sprite-gray-yellow.png) no-repeat 0 0;
  position: absolute;
  top: 50%;
  cursor: pointer;
  text-indent: -9999px;
  margin: -20px 0 0;
}

.flex-direction-nav .flex-prev {
  background-position: 0 -52px;
  left: 5px;
}

.flex-direction-nav .flex-prev:hover {
  background-position: -42px -52px;
}

.flex-direction-nav .flex-next {
  background-position: -21px -52px;
  right: 5px;
}

.flex-direction-nav .flex-next:hover {
  background-position: -63px -52px;
}

.flex-direction-nav .flex-disabled {
  opacity: .3 !important;
  filter: alpha(opacity=30);
  cursor: default;
}

.flex-control-nav {
  position: absolute;
  top: 10px;
  right: 10px;
}

.loading .flex-control-nav {
  display: none;
}

.flex-control-nav li {
  zoom: 1;
  display: inline;
  margin: 0 4px;
}

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-align: left;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.flex-control-paging li a:hover {
  background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
  background: #6c80cd url(../img/pattern.png);
  cursor: default;
}

.flex-control-thumbs {
  position: static;
  overflow: hidden;
  margin: 5px 0 0;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: .7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

.flexslider.interior-nav {
  margin-bottom: 20px;
}

.loading .interior-nav .slides > li:first-child {
  display: block;
}

.interior-nav li .thumb {
  margin-bottom: 0;
}

.interior-nav ul.flex-direction-nav {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

.interior-nav ul.flex-direction-nav a {
  width: 29px;
  height: 29px;
  opacity: .5;
  margin: -15px 0 0;
}

.interior-nav:hover ul.flex-direction-nav a {
  opacity: 1;
}

.interior-nav ul.flex-direction-nav .flex-prev {
  background-position: -194px -82px;
  left: 5px;
}

.interior-nav ul.flex-direction-nav .flex-next {
  background-position: -223px -82px;
  right: 5px;
}

.detailed .slides .info, .simple .slides .info {
  background: #222;
  color: #fff;
}

.detailed .slides .info h3 {
  padding: 10px 10px 0;
}

.detailed .slides .info p {
  padding: 0 10px 10px;
}

.mini-detailed .slides .info p, .simple .slides .info p {
  padding: 5px 10px;
}

/* roundabout */
.roundabout ul {
  height: 122px;
  overflow: hidden;
}

.roundabout li {
  width: 75%;
  cursor: pointer;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  margin: 0;
}

.roundabout li.roundabout-in-focus {
  cursor: url(../img/openhand.cur), default;
}

.roundabout li img {
  display: block;
}

.roundabout li .text {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #222;
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all .25s linear;
  -moz-transition: all .25s linear;
  -o-transition: all .25s linear;
  -ms-transition: all .25s linear;
  transition: all .25s linear;
}

.roundabout li.roundabout-in-focus .text {
  opacity: 1;
  filter: none;
}

.roundabout li .info {
  position: relative;
}

.roundabout li .text p {
  color: #fff;
  margin: 0;
  padding: 3px 5px;
}

.roundabout li .text p a.link {
  float: right;
  position: relative;
  top: -3px;
  left: 4px;
  padding: 5px;
}

.roundabout li .text p a.link span {
  display: block;
  width: 17px;
  height: 17px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -47px -19px;
}

/* panorama360 */
.panorama, .panorama-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.panorama {
  height: 260px;
}

.panorama, .panorama-container {
  position: relative;
}

.panorama-container img {
  height: 100%;
  position: absolute;
  top: 0;
}

.panorama .info, .panorama-view .area {
  position: absolute;
  display: block;
}

.panorama .info {
  right: 10px;
  bottom: 10px;
}

.panorama .controls {
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.panorama .controls a {
  display: block;
  float: left;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 7px;
  font-size: 15px;
  cursor: pointer;
}

.panorama .preloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.02) url(../img/loading.gif) no-repeat center center;
}

.panorama-view {
  cursor: url(../img/openhand.cur), default;
}

.panorama-view.grab {
  cursor: url(../img/closedhand.cur), default;
}

.panorama .info, .panorama .controls a {
  background: #6c80cd url(../img/pattern.png);
  color: #222;
  font: bold 11px/14px Arial, sans-serif;
  text-decoration: none;
  padding: 6px 7px;
  -webkit-transition: all .25s linear;
  -moz-transition: all .25s linear;
  -o-transition: all .25s linear;
  -ms-transition: all .25s linear;
  transition: all .25s linear;
}

.panorama .info:hover, .panorama .controls a:hover {
  color: #fff;
  background: #222;
}

.panorama-view .area {
  opacity: .25;
  filter: alpha(opacity=25);
  background-color: #000;
  cursor: pointer;
  overflow: hidden;
  text-indent: -9999px;
}

.panorama-view .area:hover {
  opacity: .5;
  filter: alpha(opacity=50);
}

.panorama-view img {
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.panorama .info, .panorama-view .area, .panorama .controls a {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.panorama .controls a.prev span, .panorama .controls a.stop span, .panorama .controls a.next span {
  display: block;
  text-indent: -999px;
  overflow: hidden;
  background: url(../img/sprite-gray-yellow.png) no-repeat -999px 0;
}

.panorama .controls a.prev span, .panorama .controls a.next span {
  width: 10px;
  height: 14px;
}

.panorama .controls a.prev span {
  background-position: -194px -113px;
}

.panorama .controls a.prev:hover span {
  background-position: -194px -129px;
}

.panorama .controls a.next span {
  background-position: -205px -113px;
}

.panorama .controls a.next:hover span {
  background-position: -205px -129px;
}

.panorama .controls a.stop span {
  margin: 2px 1px;
  width: 10px;
  height: 10px;
  background-position: -216px -115px;
}

.panorama .controls a.stop:hover span {
  background-position: -216px -131px;
}

/* accordion */
.accordion .accordion-title {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  padding: 5px 0;
}

.accordion .accordion-title:before {
  content: '+';
  font-weight: normal;
  float: left;
  width: 19px;
  height: 19px;
  line-height: 19px;
  margin-right: 10px;
  text-align: center;
  background: #eee url(../img/pattern.png);
}

.accordion .active .accordion-title:before, .accordion .accordion-title:hover:before {
  background-color: #6c80cd;
}

.accordion .active .accordion-title:before, .no-js .accordion .accordion-title:before {
  content: '\2013';
}

.accordion .accordion-content {
  font-size: 13px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all .25s linear;
  -moz-transition: all .25s linear;
  -o-transition: all .25s linear;
  -ms-transition: all .25s linear;
  transition: all .25s linear;
}

.accordion .active .accordion-content, .no-js .accordion .accordion-content {
  height: auto;
  opacity: 1;
  filter: none;
}

/* tabs */
.tab-container {
  margin-bottom: 20px;
}

.tab-container .tab_content {
  border: 1px solid #ddd;
  background: #fff;
  padding: 20px 20px 0;
}

.tabs {
  list-style: none;
  margin-bottom: 0;
}

.tabs li {
  float: left;
  margin: 3px 5px 0 0;
}

.tabs li a {
  display: block;
  text-decoration: none;
  background: #eee url(../img/pattern.png);
  border: 1px solid #ddd;
  border-bottom: 0;
  padding: 5px 20px;
}

.tabs li a:hover {
  background-color: #ddd;
}

.tabs li.active {
  margin-top: 0;
  margin-bottom: -1px;
}

.oldie .tabs li.active {
  position: relative;
  top: 1px;
}

.tabs li.active a {
  border-top: 3px solid #6c80cd;
  background: #fff;
  padding: 6px 20px;
}

/* fancybox */
.fancybox-wrap, .fancybox-skin, .fancybox-outer, .fancybox-inner, .fancybox-image, .fancybox-wrap iframe, .fancybox-wrap object, .fancybox-nav, .fancybox-nav span, .fancybox-tmp {
  border: 0;
  outline: none;
  vertical-align: top;
  margin: 0;
  padding: 0;
}

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.fancybox-opened {
  z-index: 8030;
}

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
  -moz-box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
  box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
}

.fancybox-outer, .fancybox-inner {
  position: relative;
}

.fancybox-inner {
  overflow: hidden;
}

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  white-space: nowrap;
  margin: 0;
  padding: 15px;
}

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url(../img/fancybox_sprite.png);
}

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: .8;
  cursor: pointer;
  z-index: 8060;
  -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
}

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(../img/fancybox_loading.gif) center center no-repeat;
}

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url(../img/blank.gif);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}

.fancybox-prev {
  left: 0;
}

.fancybox-next {
  right: 0;
}

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}

.fancybox-nav:hover span {
  visibility: visible;
}

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: #000 url(../img/pattern.png);
  background-color: rgba(0, 0, 0, 0.8);
}

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}

.fancybox-opened .fancybox-title {
  visibility: visible;
}

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
  padding: 2px 20px;
}

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}

.fancybox-title-inside-wrap {
  padding-top: 10px;
}

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  background: rgba(0, 0, 0, .8);
  padding: 10px;
}

/* alert boxes */
.alert {
  position: relative;
  margin-bottom: 10px;
  font-size: 13px;
  border-left: 3px solid #6c80cd;
  padding: 10px 12px;
}

.alert.info {
  background: #eee url(../img/pattern.png);
  border-color: #bbb;
}

.alert.success {
  background: #dbfac9 url(../img/pattern.png);
  border-color: #9fcc85;
}

.alert.notice {
  background: #fff8be url(../img/pattern.png);
  border-color: #6c80cd;
}

.alert.error {
  background: #ffd7c9 url(../img/pattern.png);
  border-color: #e29980;
}

/* pricing table */
.price-item {
  text-align: center;
  border: 1px solid #ddd;
  background: #f8f8f8;
  margin-bottom: 20px;
}

.price-item .price-title {
  background: #eee url(../img/pattern.png);
  border-bottom: 1px solid #ddd;
  margin: 0;
  padding: 10px;
}

.price-item .price-tag {
  display: block;
  border-bottom: 1px solid #eee;
  margin-bottom: 20px;
  font-size: 21px;
  padding: 10px;
}

.price-item .price-tag span {
  font-size: 13px;
  font-weight: normal;
  color: #bbb;
}

.price-item ul {
  display: block;
  padding: 0 10px;
}

.price-item.special, .price-item.special .price-title, .price-item.special .price-tag {
  border-color: #6c80cd;
}

.price-item.special .price-title {
  background-color: #6c80cd;
}

/* thumb image */
.thumb {
  position: relative;
  margin-bottom: 20px;
  cursor: pointer;
  overflow: hidden;
}

.thumb a, .thumb a img {
  display: block;
}

.thumb a img {
  width: 100%;
}

.thumb .info {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all .25s linear;
  -moz-transition: all .25s linear;
  -o-transition: all .25s linear;
  -ms-transition: all .25s linear;
  transition: all .25s linear;
}

.thumb:hover .info {
  opacity: 1;
  filter: none;
}

.thumb .pattern {
  background: url(../img/pattern.png);
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.thumb .text {
  display: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #222;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 10px 0;
}

.thumb:hover .text {
  display: block;
}

.thumb .text strong, .thumb .text em {
  display: block;
  line-height: 15px;
  padding: 0 10px;
}

.thumb .text strong {
  font-size: 12px;
  padding-bottom: 5px;
  text-transform: uppercase;
}

.thumb .text em {
  font-size: 11px;
  line-height: 17px;
}

.thumb .text span {
  display: inline-block;
  background: url(../img/sprite-gray-yellow.png) no-repeat;
}

.thumb .text em.date span {
  width: 14px;
  height: 17px;
  background-position: -38px 0;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.thumb .text a.link, .thumb .text a.fullsize {
  position: absolute;
  bottom: 10px;
  width: 17px;
  height: 17px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -999px 0;
}

.thumb .text a.link {
  right: 35px;
  background-position: -47px -19px;
}

.thumb .text a.fullsize {
  right: 10px;
  background-position: 0 -34px;
  pointer-events: auto;
}

.thumb a.button-fullsize, .thumb a.button-link, .thumb a.button-video {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 63px;
  height: 63px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -64px -83px;
  opacity: .75;
  filter: alpha(opacity=75);
  pointer-events: auto;
  margin: -32px 0 0 -32px;
}

.oldie .thumb a.button-fullsize, .oldie .thumb a.button-link, .oldie .thumb a.button-video {
  filter: alpha(opacity=25);
}

.thumb a.button-fullsize:hover, .thumb a.button-link:hover, .thumb a.button-video:hover {
  opacity: 1;
  filter: none;
}

.thumb a.button-fullsize {
  background-position: 0 -83px;
}

.thumb a.button-video {
  background-position: -128px -83px;
}

/* centered logo images for clients showcase */
.centered-image {
  margin-bottom: 20px;
  border: 1px solid #bbb;
  height: 120px
}

.centered-image:hover {
  background: #f2f2f2 url(../img/pattern.png)
}

.centered-image a {
  display: block;
  width: 100%;
  height: 100%;
  vertical-align: middle
}

.centered-image a img {
  position: relative;
  top: 50%;
  display: block;
  vertical-align: middle;
  margin: -25px auto 0
}

/* tooltip */
.tooltip:before {
  z-index: 13;
  position: absolute;
  content: "\00a0";
  display: none;
  width: 0;
  height: 0;
  top: 24px;
  left: 3px;
  opacity: .9;
  border-color: transparent transparent #222;
  border-style: solid;
  border-width: 5px;
}

.tooltip:hover:before {
  display: block;
}

.tooltip:hover {
  z-index: 10;
  position: relative;
}

.tooltip:after {
  content: attr(tip);
  display: none;
  position: absolute;
  top: 10px;
  left: -20px;
  background: #222;
  color: #fff;
  opacity: .9;
  text-align: center;
  line-height: 16px;
  min-width: 40px;
  width: auto;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: normal;
  text-indent: 0;
  margin: 1em 0 3em;
  padding: 4px 5px 5px;
}

.tooltip:hover:after {
  display: block;
  font-size: 11px;
  margin: 24px 0 0 10px;
}

/* dropcap */
.dropcap {
  float: left;
  background: #eee url(../img/pattern.png);
  width: 38px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-size: 23px;
  margin: 2px 8px 0 0;
}

.dropcap.yellow {
  background-color: #6c80cd;
}

/* filter and navigation links */
.filter a {
  display: inline-block;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.filter a.active {
  background: #6c80cd url(../img/pattern.png);
}

.filter-container h5, .filter-container .thumb, .isotope .thumb, .isotope p.smaller, .isotope h5 {
  margin-bottom: 10px;
}

.filter-container h5 a, .isotope h5 a {
  text-decoration: none;
}

.navigation a {
  color: #bbb;
  text-decoration: none;
  margin-bottom: 10px;
  padding: 5px;
}

.navigation a:hover {
  color: #333;
}

.navigation a span {
  display: inline-block;
  width: 13px;
  height: 19px;
  position: relative;
  top: 4px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -999px 0;
}

.ie7 .navigation a span {
  display: inline;
  top: 5px;
}

.navigation a.prev span {
  margin-right: 8px;
  background-position: -84px -52px;
}

.navigation a.prev:hover span {
  background-position: -134px -52px;
}

.navigation a.next span {
  margin-left: 8px;
  background-position: -99px -52px;
}

.navigation a.next:hover span {
  background-position: -149px -52px;
}

.navigation a.all {
  border-left: 1px solid #ddd;
  padding-left: 15px;
  margin-left: 5px;
}

.navigation a.all span {
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background-position: -113px -52px;
}

.navigation a.all:hover span {
  background-position: -163px -52px;
}

.ie7 .navigation a.next span, .ie7 .navigation a.all span {
  left: 8px;
}

.navigation a.no-border {
  border: 0;
  margin-left: 0;
  padding-left: 0;
}

/* responsive video */
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  background: url(../img/loading.gif) no-repeat center center;
}

.video-container iframe, .video-container object, .video-container embed, .video-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  max-width: 100%;
}

.gmap img {
  display: block;
  margin-bottom: 20px;
}

/* transitions */
@-webkit-keyframes shake {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateX(0);
  }
  40% {
    -webkit-transform: translateX(-10px);
  }
  60% {
    -webkit-transform: translateX(-5px);
  }
}

@-moz-keyframes shake {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateX(0);
  }
  40% {
    -moz-transform: translateX(-10px);
  }
  60% {
    -moz-transform: translateX(-5px);
  }
}

@-o-keyframes shake {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateX(0);
  }
  40% {
    -o-transform: translateX(-10px);
  }
  60% {
    -o-transform: translateX(-5px);
  }
}

@keyframes shake {
  0%, 20%, 50%, 80%, 100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(-5px);
  }
}

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@-moz-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@-o-keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1;
  }
  25%, 75% {
    opacity: 0;
  }
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
  }
}

@-moz-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-10px);
  }
  60% {
    -moz-transform: translateY(-5px);
  }
}

@-o-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-10px);
  }
  60% {
    -o-transform: translateY(-5px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
  }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scale(1) rotate(0);
  }
}

@-moz-keyframes tada {
  0% {
    -moz-transform: scale(1);
  }
  10%, 20% {
    -moz-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -moz-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -moz-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -moz-transform: scale(1) rotate(0);
  }
}

@-o-keyframes tada {
  0% {
    -o-transform: scale(1);
  }
  10%, 20% {
    -o-transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    -o-transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    -o-transform: scale(1.1) rotate(-3deg);
  }
  100% {
    -o-transform: scale(1) rotate(0);
  }
}

@keyframes tada {
  0% {
    transform: scale(1);
  }
  10%, 20% {
    transform: scale(0.9) rotate(-3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale(1.1) rotate(3deg);
  }
  40%, 60%, 80% {
    transform: scale(1.1) rotate(-3deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
}

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
  }
  20% {
    -webkit-transform: rotate(10deg);
  }
  40% {
    -webkit-transform: rotate(-5deg);
  }
  60% {
    -webkit-transform: rotate(2deg);
  }
  80% {
    -webkit-transform: rotate(-3deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@-moz-keyframes swing {
  20% {
    -moz-transform: rotate(10deg);
  }
  40% {
    -moz-transform: rotate(-5deg);
  }
  60% {
    -moz-transform: rotate(2deg);
  }
  80% {
    -moz-transform: rotate(-2deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}

@-o-keyframes swing {
  20% {
    -o-transform: rotate(10deg);
  }
  40% {
    -o-transform: rotate(-5deg);
  }
  60% {
    -o-transform: rotate(2deg);
  }
  80% {
    -o-transform: rotate(-2deg);
  }
  100% {
    -o-transform: rotate(0deg);
  }
}

@keyframes swing {
  20% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  60% {
    transform: rotate(2deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
  }
  15% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
  }
  30% {
    -webkit-transform: translateX(10%) rotate(3deg);
  }
  45% {
    -webkit-transform: translateX(-10%) rotate(-3deg);
  }
  60% {
    -webkit-transform: translateX(5%) rotate(2deg);
  }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -webkit-transform: translateX(0%);
  }
}

@-moz-keyframes wobble {
  0% {
    -moz-transform: translateX(0%);
  }
  15% {
    -moz-transform: translateX(-15%) rotate(-3deg);
  }
  30% {
    -moz-transform: translateX(10%) rotate(3deg);
  }
  45% {
    -moz-transform: translateX(-10%) rotate(-3deg);
  }
  60% {
    -moz-transform: translateX(5%) rotate(2deg);
  }
  75% {
    -moz-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -moz-transform: translateX(0%);
  }
}

@-o-keyframes wobble {
  0% {
    -o-transform: translateX(0%);
  }
  15% {
    -o-transform: translateX(-15%) rotate(-3deg);
  }
  30% {
    -o-transform: translateX(10%) rotate(3deg);
  }
  45% {
    -o-transform: translateX(-10%) rotate(-3deg);
  }
  60% {
    -o-transform: translateX(5%) rotate(2deg);
  }
  75% {
    -o-transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    -o-transform: translateX(0%);
  }
}

@keyframes wobble {
  0% {
    transform: translateX(0%);
  }
  15% {
    transform: translateX(-15%) rotate(-3deg);
  }
  30% {
    transform: translateX(10%) rotate(3deg);
  }
  45% {
    transform: translateX(-10%) rotate(-3deg);
  }
  60% {
    transform: translateX(5%) rotate(2deg);
  }
  75% {
    transform: translateX(-5%) rotate(-1deg);
  }
  100% {
    transform: translateX(0%);
  }
}

@-webkit-keyframes wiggle {
  0% {
    -webkit-transform: skewX(9deg);
  }
  10% {
    -webkit-transform: skewX(-8deg);
  }
  20% {
    -webkit-transform: skewX(7deg);
  }
  30% {
    -webkit-transform: skewX(-6deg);
  }
  40% {
    -webkit-transform: skewX(5deg);
  }
  50% {
    -webkit-transform: skewX(-4deg);
  }
  60% {
    -webkit-transform: skewX(3deg);
  }
  70% {
    -webkit-transform: skewX(-2deg);
  }
  80% {
    -webkit-transform: skewX(1deg);
  }
  90% {
    -webkit-transform: skewX(0deg);
  }
  100% {
    -webkit-transform: skewX(0deg);
  }
}

@-moz-keyframes wiggle {
  0% {
    -moz-transform: skewX(9deg);
  }
  10% {
    -moz-transform: skewX(-8deg);
  }
  20% {
    -moz-transform: skewX(7deg);
  }
  30% {
    -moz-transform: skewX(-6deg);
  }
  40% {
    -moz-transform: skewX(5deg);
  }
  50% {
    -moz-transform: skewX(-4deg);
  }
  60% {
    -moz-transform: skewX(3deg);
  }
  70% {
    -moz-transform: skewX(-2deg);
  }
  80% {
    -moz-transform: skewX(1deg);
  }
  90% {
    -moz-transform: skewX(0deg);
  }
  100% {
    -moz-transform: skewX(0deg);
  }
}

@-o-keyframes wiggle {
  0% {
    -o-transform: skewX(9deg);
  }
  10% {
    -o-transform: skewX(-8deg);
  }
  20% {
    -o-transform: skewX(7deg);
  }
  30% {
    -o-transform: skewX(-6deg);
  }
  40% {
    -o-transform: skewX(5deg);
  }
  50% {
    -o-transform: skewX(-4deg);
  }
  60% {
    -o-transform: skewX(3deg);
  }
  70% {
    -o-transform: skewX(-2deg);
  }
  80% {
    -o-transform: skewX(1deg);
  }
  90% {
    -o-transform: skewX(0deg);
  }
  100% {
    -o-transform: skewX(0deg);
  }
}

@keyframes wiggle {
  0% {
    transform: skewX(9deg);
  }
  10% {
    transform: skewX(-8deg);
  }
  20% {
    transform: skewX(7deg);
  }
  30% {
    transform: skewX(-6deg);
  }
  40% {
    transform: skewX(5deg);
  }
  50% {
    transform: skewX(-4deg);
  }
  60% {
    transform: skewX(3deg);
  }
  70% {
    transform: skewX(-2deg);
  }
  80% {
    transform: skewX(1deg);
  }
  90% {
    transform: skewX(0deg);
  }
  100% {
    transform: skewX(0deg);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
  }
  25% {
    -webkit-transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulse {
  0% {
    -moz-transform: scale(1);
  }
  25% {
    -moz-transform: scale(1.1);
  }
  50% {
    -moz-transform: scale(1);
  }
  75% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes pulse {
  0% {
    -o-transform: scale(1);
  }
  25% {
    -o-transform: scale(1.1);
  }
  50% {
    -o-transform: scale(1);
  }
  75% {
    -o-transform: scale(1.1);
  }
  100% {
    -o-transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
  }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
  }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(.95);
    -webkit-animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
  }
}

@-moz-keyframes flip {
  0% {
    -moz-transform: perspective(400px) rotateY(0);
    -moz-animation-timing-function: ease-out;
  }
  40% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -moz-animation-timing-function: ease-out;
  }
  50% {
    -moz-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -moz-animation-timing-function: ease-in;
  }
  80% {
    -moz-transform: perspective(400px) rotateY(360deg) scale(.95);
    -moz-animation-timing-function: ease-in;
  }
  100% {
    -moz-transform: perspective(400px) scale(1);
    -moz-animation-timing-function: ease-in;
  }
}

@-o-keyframes flip {
  0% {
    -o-transform: perspective(400px) rotateY(0);
    -o-animation-timing-function: ease-out;
  }
  40% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -o-animation-timing-function: ease-out;
  }
  50% {
    -o-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -o-animation-timing-function: ease-in;
  }
  80% {
    -o-transform: perspective(400px) rotateY(360deg) scale(.95);
    -o-animation-timing-function: ease-in;
  }
  100% {
    -o-transform: perspective(400px) scale(1);
    -o-animation-timing-function: ease-in;
  }
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }
  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }
  80% {
    transform: perspective(400px) rotateY(360deg) scale(.95);
    animation-timing-function: ease-in;
  }
  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}

.shake:hover {
  -webkit-animation-name: shake;
  -moz-animation-name: shake;
  -o-animation-name: shake;
  animation-name: shake;
}

.flash:hover {
  -webkit-animation-name: flash;
  -moz-animation-name: flash;
  -o-animation-name: flash;
  animation-name: flash;
}

.bounce:hover {
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  animation-name: bounce;
}

.tada:hover {
  -webkit-animation-name: tada;
  -moz-animation-name: tada;
  -o-animation-name: tada;
  animation-name: tada;
}

.swing:hover {
  -webkit-transform-origin: top center;
  -moz-transform-origin: top center;
  -o-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  -moz-animation-name: swing;
  -o-animation-name: swing;
  animation-name: swing;
}

.wobble:hover {
  -webkit-animation-name: wobble;
  -moz-animation-name: wobble;
  -o-animation-name: wobble;
  animation-name: wobble;
}

.pulse:hover {
  -webkit-animation-name: pulse;
  -moz-animation-name: pulse;
  -o-animation-name: pulse;
  animation-name: pulse;
}

.wiggle:hover {
  -webkit-animation-name: wiggle;
  -moz-animation-name: wiggle;
  -o-animation-name: wiggle;
  animation-name: wiggle;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-timing-function: ease-in;
  -o-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

.flip:hover {
  -webkit-backface-visibility: visible !important;
  -webkit-animation-name: flip;
  -moz-backface-visibility: visible !important;
  -moz-animation-name: flip;
  -o-backface-visibility: visible !important;
  -o-animation-name: flip;
  backface-visibility: visible !important;
  animation-name: flip;
}

.animated {
  -webkit-animation-duration: 750ms;
  -moz-animation-duration: 750ms;
  -o-animation-duration: 750ms;
  animation-duration: 750ms;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.hinge {
  -webkit-animation-duration: 1.4s;
  -moz-animation-duration: 1.4s;
  -o-animation-duration: 1.4s;
  animation-duration: 1.4s;
}

/* header */
#logo h1 {
  margin-top: 26px;
  margin-bottom: 19px;
}

#logo h1 a {
  display: inline-block;
  text-decoration: none;
}

.oldie #logo h1 a {
  display: inline;
}

#logo h1 a img {
  display: block;
}

#menu {
  position: relative;
}

#menu ul {
  display: none;
  list-style: none;
  z-index: 6;
  margin: 0;
}

#menu.mobile {
  position: absolute;
  top: 0;
  right: 0;
}

#menu.mobile ul {
  display: block;
  position: absolute;
  top: 50px;
  right: 0;
  background: #444;
}

.ie7 #menu.mobile ul {
  top: 75px;
}

#menu.mobile ul li {
  display: block;
  margin-bottom: 0;
}

#menu.mobile ul a {
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 13px;
  text-align: right;
  color: #fff;
  padding: 6px 12px;
}

#menu.mobile ul a:hover {
  background: #333;
}

#menu.mobile ul .current_page_item a {
  color: #333;
  background: #6c80cd url(../img/pattern.png);
}

#menu.mobile li.arrow a:before, #menu.mobile li.arrow li.arrow a:before {
  content: '\002B ';
}

#menu.mobile li.show-menu a:before, #menu.mobile li.show-menu li.show-menu a:before {
  content: '\2013 ';
  position: relative;
  top: -1px;
  left: -1px;
}

#menu.mobile li.arrow li a:before, #menu.mobile li.arrow li.arrow li a:before {
  content: '';
}

#menu.mobile ul ul {
  display: none;
  position: static;
  background: rgba(0, 0, 0, 0.1);
}

#menu.mobile ul li.show-menu ul, #menu.mobile ul li.show-menu ul li.show-menu ul {
  display: block;
}

#menu.mobile ul li.show-menu ul ul {
  display: none;
}

#menu.mobile ul ul a {
  font-size: 11px;
  color: #ccc;
}

#menu.mobile ul .search-link form {
  display: none;
}

#header #switch {
  position: absolute;
  top: 20px;
  right: 0;
  display: block;
  width: 70px;
  background: #333;
  color: #fff;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
  cursor: pointer;
  z-index: 6;
  padding: 4px 0;
}

.ie7 #header #switch {
  top: 45px;
}

#header #switch:hover {
  background: #444;
}

#header .sep {
  display: block;
  margin-bottom: 20px;
}

/* call to actiob box */
.call-to-action {
  margin-bottom: 30px;
  border: 1px solid #ddd;
  background: #f0f0f0;
  background: -moz-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f0f0f0), color-stop(100%, #ffffff));
  background: -webkit-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
  background: -ms-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #f0f0f0 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f0f0', endColorstr='#ffffff', GradientType=0);
  padding: 10px 20px 0 15px;
}

.call-to-action h3 {
  margin-bottom: 0;
}

.call-to-action p {
  font-style: italic;
  color: #666;
  margin-bottom: 10px;
}

#content {
  z-index: 0;
}

#content .sep-small {
  margin-bottom: 20px;
}

#sidebar div.sep-big {
  height: 2px;
  margin: 2px 0 15px;
}

#sidebar p.small {
  margin-bottom: 15px;
}

.social a {
  display: inline-block;
  position: relative;
  background: url(../img/sprite-gray-yellow.png) no-repeat -999px 0;
  text-indent: -9999px;
}

.ie7 .social a {
  float: left;
  margin-right: 5px;
}

.social a.email {
  top: 1px;
  width: 16px;
  height: 13px;
  background-position: -84px -19px;
}

.oldie .social a.email {
  top: 0;
}

.social a.email:hover {
  background-position: -84px -35px;
}

.social a.twitter {
  top: 1px;
  width: 20px;
  height: 15px;
  background-position: -101px -19px;
}

.oldie .social a.twitter {
  top: 0;
}

.social a.twitter:hover {
  background-position: -101px -35px;
}

.social a.facebook {
  top: 2px;
  width: 16px;
  height: 16px;
  background-position: -121px -19px;
}

.oldie .social a.facebook {
  top: 0;
}

.social a.facebook:hover {
  background-position: -121px -35px;
}

.icon-date-gray {
  display: inline-block;
  width: 13px;
  height: 15px;
  margin-left: 1px;
  margin-right: 6px;
  position: relative;
  top: 2px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -67px 0;
}

.icon-link-gray {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  position: relative;
  top: 3px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -31px -19px;
}

.ie7 .icon-date-gray, .ie7 .icon-link-gray {
  display: inline;
  top: -2px;
}

.isotope {
  padding-bottom: 50px;
}

#infscr-loading {
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  margin-left: -133px;
  bottom: 0;
  width: 250px;
  padding: 8px;
  line-height: 38px;
  background: #000;
  opacity: 0.8;
  filter: alpha(opacity=80);
  color: #fff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

#infscr-loading img {
  float: left;
  width: 38px;
}

.meta p {
  margin-bottom: 0;
  color: #666;
}

.meta p a {
  color: #666;
}

.meta p a:hover {
  color: #222;
}

.meta span {
  float: left;
  margin-right: 9px;
  background: url(../img/sprite-gray-yellow.png) no-repeat -999px 0;
  position: relative;
  top: 2px;
}

.meta span.icon-date {
  width: 13px;
  height: 15px;
  background-position: -10px 0;
}

.meta span.icon-tag {
  width: 14px;
  height: 14px;
  margin-right: 8px;
  background-position: 0 -19px;
  top: 5px;
}

.meta span.icon-comments {
  width: 15px;
  height: 13px;
  margin-right: 7px;
  background-position: -15px -19px;
  top: 5px;
}

.commentlist {
  list-style: none;
  margin-left: 0;
}

.commentlist .comment {
  border-top: 1px solid #ddd;
  padding-top: 20px;
  margin-top: 20px;
}

.commentlist .bypostauthor {
  border-top: 3px solid #6c80cd;
  background: #f8f8f8 url(../img/pattern.png);
  padding-bottom: 20px;
}

.commentlist .comment .comment-author {
  float: left;
  margin-right: 10px;
}

.commentlist .comment .comment-author img {
  display: block;
}

.commentlist .comment h6 {
  margin-bottom: 5px;
}

.commentlist .comment h6 a.name {
  text-decoration: none;
}

.commentlist .comment a.reply {
  font-weight: normal;
  text-transform: none;
  font-size: 11px;
  margin-left: 5px;
  position: relative;
  top: -2px;
  color: #999;
}

.commentlist .comment a.reply:hover {
  color: #222;
}

.commentlist .comment p.date {
  font-size: 12px;
}

.commentlist .comment p {
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 0;
  margin-top: 5px;
}

.commentlist .comment .children {
  font-size: 100%;
  margin: 0 0 0 30px;
}

/* footer */
#footer {
  background: #333;
  color: #bbb;
  margin-top: 40px;
}

#footer h3 {
  color: #fefefe;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

#footer p, #footer ul {
  line-height: 24px;
  font-size: 12px;
  display: inline;
}

#footer ul li {
  line-height: 24px;
  margin-bottom: 0;
  display: inline;
  margin-right: 10px;
}

#footer a {
  color: #bbb;
  font-size: 12px;
}

#footer a:hover {
  color: #ddd;
}

#footer a:hover img {
  opacity: .9;
  filter: alpha(opacity=90);
}

#footer .post-item {
  margin-bottom: 13px;
}

#footer .post-item img {
  float: left;
  margin-right: 10px;
}

#footer .post-item .title {
  line-height: 14px;
  padding: 5px 0;
}

#footer .post-item .date, #footer .post-item .comments {
  color: #777;
  font-size: 11px;
}

#footer .post-item .date span {
  display: inline-block;
  width: 10px;
  height: 12px;
  margin-right: 4px;
  position: relative;
  top: 1px;
  background: url(../img/sprite-gray-yellow.png) no-repeat 0 0;
}

#footer .post-item .comments {
  float: right;
}

#footer .post-item .comments:hover {
  color: #999;
}

#footer .flickr-feed a {
  float: left;
  display: block;
  width: 60px;
  height: 60px;
  margin-right: 13px;
  margin-bottom: 13px;
}

#footer .flickr-feed a img {
  display: block
}

#footer .bottom {
  margin-top: 20px;
  background: #2e2e2e;
  vertical-align: middle;
  padding: 20px 0 10px;
}

/* bigger then mobile version */
@media screen and (min-width: 480px) {
  .flexslider .new-ribbon {
    width: 71px;
    height: 71px;
    top: -3px;
    right: -3px;
    background-position: -184px 0;
  }

  .roundabout ul {
    height: 168px;
  }

  .detailed .slides .info h3 {
    padding: 20px 20px 0;
  }

  .detailed .slides .info p {
    padding: 0 20px 20px;
  }

  .mini-detailed .slides .info p, .simple .slides .info p {
    padding: 10px 20px;
  }
}

/* small ipad version */
@media screen and (min-width: 480px) and (max-width: 768px) {
  .container {
    width: 420px;
  }

  .flex-direction-nav .flex-prev {
    left: -25px;
  }

  .flex-direction-nav .flex-next {
    right: -25px;
  }
}

/* big ipad version */
@media screen and (min-width: 768px) {
  .container {
    width: 720px;
  }

  .container .col {
    float: left;
    display: inline;
  }

  .container .col, #header .sep {
    margin-left: 10px;
    margin-right: 10px;
  }

  .container .grid1.col {
    width: 40px;
  }

  .container .grid2.col {
    width: 100px;
  }

  .container .grid3.col {
    width: 160px;
  }

  .container .grid4.col {
    width: 220px;
  }

  .container .grid5.col {
    width: 280px;
  }

  .container .grid6.col {
    width: 340px;
  }

  .container .grid7.col {
    width: 400px;
  }

  .container .grid8.col {
    width: 460px;
  }

  .container .grid9.col {
    width: 520px;
  }

  .container .grid10.col {
    width: 580px;
  }

  .container .grid11.col {
    width: 640px;
  }

  .container .grid12.col {
    width: 700px;
  }

  .border-header {
    border-top: 8px solid #bbb
  }

  body {
    font-size: 14px;
  }

  blockquote {
    font-size: 13px;
  }

  h1 {
    font-size: 35px;
    line-height: 40px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 8px;
  }

  h3 {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 4px;
  }

  h4 {
    font-size: 19px;
    line-height: 24px;
  }

  h5 {
    font-size: 17px;
    line-height: 21px;
  }

  h6 {
    font-size: 15px;
    line-height: 21px;
  }

  .button, button, input[type=submit], input[type=reset], input[type=button] {
    font-size: 15px;
  }

  .hide-on-mobile {
    display: block;
  }

  a.hide-on-mobile {
    display: inline-block;
  }

  .page-title.left {
    float: left;
  }

  .subtitle {
    float: right;
    line-height: 40px;
    text-transform: uppercase;
  }

  .filter a {
    display: inline;
    margin-bottom: 0;
  }

  .commentlist .comment .comment-body {
    position: relative;
    margin-left: 95px;
  }

  .commentlist .comment h6 {
    padding-right: 100px;
  }

  .commentlist .comment p.date {
    position: absolute;
    top: 0;
    right: 0;
  }

  .commentlist .comment p {
    margin-top: 0;
  }

  .commentlist .comment .children {
    margin-left: 95px;
  }

  #header #switch {
    display: none;
  }

  #menu {
    position: static;
  }

  #menu ul {
    float: right;
    display: block;
    height: 119px;
    text-align: right;
    margin: 0;
  }

  #menu li {
    float: left;
    margin: 0;
  }

  #menu a {
    display: block;
    position: relative;
    height: 119px;
    line-height: 120px;
    padding-left: 6px;
    padding-right: 6px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    color: #333;
  }

  #menu a:hover, #menu li:hover a, #menu li.hover a, #menu li.current_page_item a {
    top: -8px;
    border-top: 8px solid #6c80cd;
    color: #000;
    background: #f0f0f0;
  }

  #menu li.current_page_item a {
    color: #bbb;
    background: -moz-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #f0f0f0), color-stop(100%, #ffffff));
    background: -webkit-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
    background: -o-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
    background: -ms-linear-gradient(top, #f0f0f0 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #f0f0f0 0%, #ffffff 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f0f0', endColorstr='#ffffff', GradientType=0);
  }

  #menu li a:hover, #menu li:hover a, #menu li.hover a {
    color: #000;
    background: #fff;
    background: -moz-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffffff), color-stop(100%, #f0f0f0));
    background: -webkit-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
    background: -o-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
    background: -ms-linear-gradient(top, #ffffff 0%, #f0f0f0 100%);
    background: linear-gradient(to bottom, #ffffff 0%, #f0f0f0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#f0f0f0', GradientType=0);
  }

  #menu a:hover:before, #menu li:hover a:before, #menu li.hover a:before, #menu li.current_page_item a:before {
    content: ' ';
    position: absolute;
    top: -8px;
    left: 0;
    width: 100%;
    height: 8px;
    background: url(../img/pattern.png);
  }

  #menu ul ul a, #menu li:hover li a, #menu li.hover li a {
    top: 0;
    border-top: 0;
    background: transparent;
    filter: none;
  }

  #menu li.arrow a:after, #menu li.arrow li.arrow a:after {
    content: ' \002B';
    color: #bbb;
  }

  #menu li.arrow li a:after, #menu li.arrow li.arrow li a:after {
    content: '';
  }

  #menu li.arrow:hover a:after, #menu li.arrow li.arrow:hover a:after, #menu li.arrow li.arrow.hover a:after, #menu li.current_page_item ul a {
    color: #000;
  }

  #menu li:hover, #menu li.hover {
    position: relative;
    z-index: 15;
    cursor: default;
  }

  #menu ul ul {
    visibility: hidden;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 14;
    margin-top: -8px;
    text-align: left;
    min-width: 160px;
    height: auto;
    background: #f0f0f0;
    -webkit-box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .18);
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, .18);
  }

  .oldie #menu ul ul {
    width: 200px;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }

  #menu ul ul ul {
    top: 0;
    left: 99%;
    margin-top: 0;
  }

  #menu ul ul li {
    float: none;
    display: block;
    width: 100%;
  }

  #menu ul ul a {
    position: static;
    display: block;
    height: auto;
    line-height: 40px;
  }

  #menu ul ul a:hover {
    background: #ddd url(../img/pattern.png);
  }

  #menu ul ul a:hover:before, #menu li:hover ul a:before, #menu li.hover ul a:before {
    display: none;
  }

  #menu ul ul li.current_page_item a {
    color: #000;
    background: #6c80cd url(../img/pattern.png);
  }

  #menu li.current_page_item ul a:before {
    display: none;
  }

  #menu li:hover > ul, #menu li.hover > ul {
    visibility: visible
  }

  #menu ul .search-link a {
    display: none;
  }

  #menu ul .search-link form {
    position: relative;
    display: block;
    width: 85px;
    margin-left: 5px;
  }

  #menu ul .search-link form input[type=text] {
    position: absolute;
    left: 0;
    top: 44px;
    width: 85px;
    padding: 5px 22px 5px 8px;
  }

  .oldie #menu ul .search-link form input[type=text] {
    top: 46px;
  }

  .ie7 #menu ul .search-link form input[type=text] {
    width: 55px;
  }

  #menu ul .search-link form input[type=submit] {
    position: absolute;
    border: 0;
    background: #F9F9F9 url(../img/sprite-gray-yellow.png) no-repeat -151px -20px;
    color: #F9F9F9;
    font-size: 0;
    width: 20px;
    height: 27px;
    right: 1px;
    top: 45px;
  }

  .oldie #menu ul .search-link form input[type=submit] {
    top: 47px;
  }

  .roundabout ul {
    height: 270px;
  }

  .roundabout li .text p {
    padding: 8px 10px;
  }

  .panorama {
    height: 360px;
  }

  .flex-direction-nav .flex-prev {
    left: -30px;
  }

  .flex-direction-nav .flex-next {
    right: -30px;
  }

  .flexslider.interior-nav {
    margin: 0;
  }

  .staff .social.float-right {
    float: left;
  }

  .detailed .slides .info, .simple .slides .info {
    background: rgba(0, 0, 0, 0.7);
    display: block;
    position: absolute;
    top: 0;
    left: -40%;
    height: 100%;
    width: 40%;
    -webkit-transition: all .5s ease;
    -o-transition: all .5s ease;
    -moz-transition: all .5s ease;
    transition: all .5s ease;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .detailed .slides .flex-active-slide .info, .simple .slides .flex-active-slide .info {
    left: 0;
    opacity: .9;
    filter: alpha(opacity=90);
  }

  div.mini-detailed .slides .info, div.simple .slides .info {
    background: transparent;
    width: 100%;
    height: auto;
    top: auto;
    bottom: 20px;
    left: 0;
  }

  .mini-detailed .slides .info p, .simple .slides .info p {
    background: #222;
    background: rgba(0, 0, 0, 0.8);
    display: inline;
    position: relative;
    margin: 0;
  }

  .mini-detailed .slides .info p:after, .simple .slides .info p:after {
    content: " ";
    display: block;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 16px solid #222;
    border-left: 16px solid rgba(0, 0, 0, 0.8);
    position: absolute;
    bottom: 0;
    right: -16px;
    z-index: 2;
  }

  #footer ul li {
    display: block;
    margin-right: 0;
  }

  #footer .bottom {
    line-height: 40px;
    vertical-align: middle;
    padding: 5px 0;
  }

  #footer .bottom img {
    vertical-align: middle;
  }

  #footer .bottom p {
    line-height: 40px;
    text-align: right;
    margin-bottom: 0;
  }

  #footer h3 {
    margin: 30px 0;
  }

  #top-link {
    display: none;
    position: fixed;
    width: 40px;
    height: 40px;
    right: 5px;
    bottom: 5px;
    background: #bbb url(../img/pattern.png);
    background-color: rgba(150, 150, 150, 0.5);
    cursor: pointer;
  }

  #top-link:hover {
    background-color: #999;
    background-color: rgba(120, 120, 120, 0.5);
  }

  #top-link span {
    display: block;
    width: 20px;
    height: 13px;
    margin-top: 14px;
    margin-left: 11px;
    background: url(../img/sprite-gray-yellow.png) no-repeat -18px -36px;
  }
}

/* full wide version */
@media screen and (min-width: 960px) {
  .container {
    width: 960px;
  }

  .container .grid1.col {
    width: 60px;
  }

  .container .grid2.col {
    width: 140px;
  }

  .container .grid3.col {
    width: 220px;
  }

  .container .grid4.col {
    width: 300px;
  }

  .container .grid5.col {
    width: 380px;
  }

  .container .grid6.col {
    width: 460px;
  }

  .container .grid7.col {
    width: 540px;
  }

  .container .grid8.col {
    width: 620px;
  }

  .container .grid9.col {
    width: 700px;
  }

  .container .grid10.col {
    width: 780px;
  }

  .container .grid11.col {
    width: 860px;
  }

  .container .grid12.col {
    width: 940px;
  }

  #menu a {
    padding-left: 14px;
    padding-right: 14px;
    font-size: 15px;
  }

  #menu ul ul {
    min-width: 200px;
  }

  #menu ul ul a {
    font-size: 14px;
  }

  #menu ul .search-link form {
    width: 104px;
    margin-left: 5px;
  }

  #menu ul .search-link form input[type=text] {
    width: 105px;
  }

  .ie7 #menu ul .search-link form input[type=text] {
    width: 75px;
  }

  .call-to-action .left {
    float: left;
  }

  .call-to-action .right {
    margin-left: 10px;
    margin-top: 10px;
    float: right;
  }

  .detailed .slides .info {
    left: -30%;
    width: 30%;
  }

  .roundabout ul {
    height: 356px;
  }

  .panorama {
    height: 520px;
  }

  .staff .social.float-right {
    float: right;
  }
}


/* full wide version */
@media all and (min-width: 1280px) {
  .container {
    width: 1200px;
  }

  .container .grid1.col {
    width: 80px;
  }

  .container .grid2.col {
    width: 180px;
  }

  .container .grid3.col {
    width: 280px;
  }

  .container .grid4.col {
    width: 380px;
  }

  .container .grid5.col {
    width: 480px;
  }

  .container .grid6.col {
    width: 580px;
  }

  .container .grid7.col {
    width: 680px;
  }

  .container .grid8.col {
    width: 780px;
  }

  .container .grid9.col {
    width: 880px;
  }

  .container .grid10.col {
    width: 980px;
  }

  .container .grid11.col {
    width: 1080px;
  }

  .container .grid12.col {
    width: 1180px;
  }

  .roundabout ul {
    height: 480px;
    margin-bottom: 0;
  }

  .panorama {
    height: 600px;
  }
}
